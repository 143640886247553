
/**
 * @description
 * CSS로 동작하는 공용 스와이퍼 신규 버전
 */

import { getPathAt } from '@/utils/path.js';

import Icon from '@/components/common/icon/Icon.vue';

export default {
  name: 'CssSwiper',
  components: { Icon },
  props: {
    title: { type: String, default: null },
    to: { type: Object, default: null }, // 우측 상단에 띄울 링크 주소
    linkText: { type: String, default: '전체보기' }, // 링크 텍스트
    sectionName: { type: String, default: null }, // 앰플리튜드 로그용
    isLoading: { type: Boolean, default: false }, // 로딩 상태에 따라서 스크롤 재정비하기 위해
    homeType: { type: String, default: null }, // 홈에서 사용중인 큐레이션의 경우 프로퍼티로 내려주면 click_product이벤트에 찍히게 해줍니다.
  },
  data() {
    return {
      swiperEl: null,
      // 좌 우 disable용 flag
      leftStop: false,
      rightStop: false,
    };
  },
  computed: {
    // sub title slot에 값이 있는지 판별
    hasSubTitleSlot: ({ $slots }) => !!$slots.subtitle,
  },
  watch: {
    isLoading(v) {
      if (!v) {
        this.$nextTick(() => {
          this.scrollDisableHandler();
          this.swiperEl.scroll({ left: 0 });
        });
      }
    },
  },
  mounted() {
    this.swiperEl = this.$refs.swiper;

    // 스크롤 좌, 우로 끝까지 도달했는지 체크
    this.$refs.swiper.addEventListener('scroll', this.scrollDisableHandler);
  },
  methods: {
    // 스와이퍼 너비만큼 이전으로
    scrollPrev() {
      // 반응형으로 하기 위해서 누른 순간에 스와이퍼의 스크롤 상태를 추적합니다
      const left = Math.ceil(this.swiperEl.scrollLeft);
      const swiperWidth = Math.ceil(this.swiperEl.clientWidth);
      this.swiperEl.scroll({
        left: left - swiperWidth - 24, // left에서 스크롤된 위치 - 스와이퍼 너비 - gap 24px
        behavior: 'smooth',
      });
    },
    // 스와이퍼 너비만큼 다음으로
    scrollNext() {
      // 반응형으로 하기 위해서 누른 순간에 스와이퍼의 스크롤 상태를 추적합니다
      const left = Math.ceil(this.swiperEl.scrollLeft);
      const swiperWidth = Math.ceil(this.swiperEl.clientWidth);
      this.swiperEl.scroll({
        left: left + swiperWidth + 24, // left에서 스크롤된 위치 + 스와이퍼 너비 + gap 24px
        behavior: 'smooth',
      });
    },
    logClickViewAll() {
      this.logUserEvent('click_view_all', {
        section: this.sectionName,
        at: getPathAt(this.$route),
        home_type: this.homeType,
      });
    },
    // 좌 우 이동이 더 가능한지 판단
    scrollDisableHandler() {
      const scrollLeft = Math.ceil(this.swiperEl.scrollLeft); // 화면 %에 따라 픽셀이 소수점으로 내려가는 케이스가 있음
      this.leftStop = scrollLeft === 0;
      this.rightStop =
        scrollLeft + this.swiperEl.clientWidth >= this.swiperEl.scrollWidth;
    },
  },
};
